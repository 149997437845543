<template>
  <main>
    <div class="selectNeuerAuftrag">
      <div v-if="showThankYou">
        <div class="row">
          <div class="columns large-12 medium-12 small-12">
            <div class="singleWrapper">
              <h2>Vielen Dank für Ihre Bestellung</h2>
              <p>In Kürze erhalten Sie per E-Mail Ihre Auftragsbestätigung sowie die Login-Daten für unser
                Kundenportal.</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="errorMessage !==''">
        <div class="row">
          <div class="columns large-push-3 large-6 medium-12 small-12">
            <div class="singleWrapper red">
              <h2>Es ist ein Fehler aufgetreten</h2>
              <p>{{ errorMessage }}</p>
              <!-- keep saved data and get back to the best possible step -->
            </div>
          </div>
        </div>
      </div>
      <div v-else class="newWasteWrapper" :class="step">
        <div class="row">
          <div class="columns large-12 medium-12 small-12">
            <div class="zipEntry selectedZip">
              <div class="row">
                <div class="large-6 columns medium-6 small-12 first">
                  <input type="number" v-model="$store.state.order.zip" readonly
                         placeholder="Postleitzahl der Lieferadresse"/>
                </div>
                <div class="large-6 columns medium-6 small-12 last">
                  <button class="btn btn-lg" @click="$router.push('/')">Postleitzahl ändern</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex" style="width: 100%; margin: 0 auto;">
          <div class="columns xlarge-6 large-12 medium-12 small-12">
            <div class="singleWrapper">
              <template v-if="step!=='waste'">
                <div class="overlay"></div>
                <button type="button" class="btn btn-right changeBtn" @click="step='waste'">Ändern</button>
              </template>
              <div class="filterBar">
                <div class="groupLabelInput withoutLabel">
                  <input type="text" v-model="filter.searchWasteType"
                         placeholder="Suchen...">
                </div>
              </div>
              <h2>Abfallart</h2>
              <p v-if="step==='waste'">Wählen Sie eine Abfallart aus</p>
              <template v-for="(category, catid) in categories">
                <div class="konfigCategoryWrap"
                     v-if="((filter.filterActive && checkItems(category)) || !filter.filterActive ) && category.category.title">
                  <div class="binvollSelect typeCategory" :class="(save.category_id === catid) ? 'active': ''"
                       @click="selectItem(catid, 'category_id')"
                  >
                    <div class="selectImage">
                      <img :src="'https://kundenportal.meindl-entsorgung.de/'+category.category.params.image"
                           v-if="category.category.params && category.category.params.image"/>
                    </div>
                    <div class="selectTitle">{{ category.category.title }}</div>
                  </div>
                </div>
                <div class="konfigWasteWrap" v-for="waste in category.items">
                  <template
                    v-if="save.category_id === catid && (filter.searchWasteType.length <= 0 || (waste && waste.filterMatch))">
                    <div class="binvollSelect typeWaste" :class="(save.waste_id === waste.id) ? 'active': ''"
                         @click="selectItem(waste.id, 'waste_id', waste); save.container_id = '';showWaste = waste; showSortenInfoDirect(waste);showWasteConfirm = false;">
                      <div class="selectImage">
                        <img :src="'https://kundenportal.meindl-entsorgung.de/'+waste.icon" v-if="waste.icon"
                             alt="">
                        <img src="/templates/binvoll/images/Placeholder_abfallart.png" v-else
                             alt=""/>
                      </div>
                      <div class="selectTitle">{{ waste.waste_name }}</div>
                      <div class="priceInfo">{{ $filters.priceEuro(waste.brutto_price) }}&nbsp;/&nbsp;{{waste.default_unit}}</div>
                      <span v-if="waste.description" @click="showSortenInfoDirect(waste)" class="showWasteInfo"><i
                        class="fa-regular fa-circle-info"></i></span>
                    </div>
                  </template>
                </div>
              </template>
              <button class="btn" type="button" @click="showWasteConfirm =true;" style="margin-top: 5px;" v-if="showWaste &&  save.container_id === ''">Bestätigen</button>
            </div>
          </div>
          <div class="columns xlarge-6 large-12 medium-12 small-12">
            <div class="singleWrapper" v-if="showWaste && showWasteConfirm">
              <template v-if="step!=='waste'">
                <div class="overlay"></div>
                <button type="button" class="btn btn-right changeBtn" @click="step='waste'">Ändern</button>
              </template>
<!--              <div class="searchContainerWrap" v-if="su">-->
<!--                <input type="text" v-model="filter.searchContainer" placeholder="Container suchen.. "/>-->
<!--              </div>-->
              <h2>Container</h2>
              <p v-if="step==='waste'">Wählen Sie einen Container aus</p>
              <div class="userContainersWrapper" v-if="showWaste.container_mapping">
                <div class="konfigContainerWrap" v-for="(container, cid) in showWaste.container_mapping"
                     v-if="save.waste_id === showWaste.id">
                  <template
                    v-if="container && container.title && (!su || (su && container.title.toLowerCase().indexOf(filter.searchContainer.toLowerCase()) >= 0 ))">
                    <div class="binvollSelect typeContainer"
                         :class="(save.container_id === container.id) ? 'active': ''"
                         @click="selectItem(container.id, 'container_id', container);showSortenInfoDirect(container)">
                      <div class="selectImage">
                        <img :src="'https://kundenportal.meindl-entsorgung.de/'+container.image" v-if="container.image"
                             alt="">
                      </div>
                      <div class="selectTitle">{{ container.title }}</div>
                      <div class="priceInfo">
                        <template v-if="container && container.prices && container.prices.transport_price_brutto">
                          {{ $filters.priceEuro(container.prices.transport_price_brutto) }}
                        </template>
                      </div>
                      <span @click="showSortenInfoDirect(container)" class="showWasteInfo" v-if="container.description"><i
                        class="fa-regular fa-circle-info"></i></span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="buttonRow">
                <button v-if="save.waste_id != '' && save.container_id != ''"
                        class="nextSelectContainer btn" type="button"
                        @click="step = 'location'; setAdditionals();">Bestätigen
                </button>
                <button v-else class="nextSelectContainer btn " type="button" disabled>Bestätigen
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex" v-if="step === 'location'  || step ==='ansprech'">
          <div class="columns large-6 medium-12 small-12">
            <div class="singleWrapper">
              <div class="flexWrap">
                <h2>Angebot</h2>
                <button class="btn btn-circle ml-auto offerButton" type="button" @click="downloadOffer"><i
                  class="fa-file-lines fa-solid"></i></button>
              </div>
              <div class="box" ref="pdfContent">
                <p><strong
                  v-if="fullDisplay && fullDisplay.waste_id && fullDisplay.waste_id.waste_name && fullDisplay.container_id && fullDisplay.container_id.title">Container
                  für {{ fullDisplay.waste_id.waste_name }}</strong><br>
                  in
                  <template v-if="$store && $store.state && $store.state.order && $store.state.order.zip">
                    {{ $store.state.order.zip }}
                  </template>
                  {{ this.getSelectedCityByZip }}<br>
                  {{ fullDisplay.container_id.title }}
                </p>
                <table style="width: 100%;">
                  <tbody>
                  <tr>
                    <td>Transportkosten (Container liefern und wieder abholen)</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.transport) }}</td>
                  </tr>
                  <tr>
                    <td>Miete monatlich (14-Tage mietfrei)</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.rentMonthly) }}</td>
                  </tr>
                  <tr>
                    <td>Entsorgungskosten pro <template v-if="showWaste && showWaste.default_unit === 't'">Tonne</template><template v-else>{{showWaste.default_unit}}</template>*</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.pricePerTon) }}</td>
                  </tr>
                  <tr v-if="fullDisplay.waste_id.dangerwaste && $store.state.prices.dangerwaste">
                    <td>Begleitscheingebühr für Entsorgung von gefährlichen Abfällen</td>
                    <td style="text-align: right;">{{ priceEuro($store.state.prices.dangerwaste) }}</td>
                  </tr>
                  <tr>
                    <td>Bezahlart</td>
                    <td style="text-align: right;">Rechnung</td>
                  </tr>
                  </tbody>
                </table>
                <template v-if="fullDisplay.waste_id.additionals.length > 0">
                  <h4 style="margin-bottom: 12px;">
                    Empfohlene Zusatzartikel</h4>
                  <template v-for="add in fullDisplay.waste_id.additionals">
                    <div class="row">
                      <div class="columns xlarge-5 large-7 medium-12 small-12">
                        <div class="checkbox"><input v-model="save.additionals[add.id].id" type="checkbox"
                                                     :id="'additional_'+add.id"><label
                          :for="'additional_'+add.id">{{ add.container_name }}</label></div>
                      </div>
                      <div class="columns xlarge-4 large-5 medium-12 small-12"><input type="number" min="1"
                                                                                      class="small"
                                                                                      v-if="save.additionals[add.id].id"
                                                                                      placeholder="Anzahl / Menge"
                                                                                      step="1"
                                                                                      v-model="save.additionals[add.id].amount"/>
                      </div>
                      <div class="columns xlarge-3 large-12 medium-12 small-12 priceCalcDisplay">
                        <template
                          v-if="add.add_price && save.additionals[add.id].amount && save.additionals[add.id].amount > 0 && save.additionals[add.id].id">
                          {{ priceEuro(add.add_price) }} x {{ save.additionals[add.id].amount }} =
                          {{ priceEuro(add.add_price * save.additionals[add.id].amount) }}
                        </template>
                      </div>
                    </div>
                  </template>
                </template>
                <p v-if="save.newLocation.stell">Zzgl. Gebühren für die Beantragung der öffentlichen Stellfläche
                </p>
                <p>Die Gesamtkosten werden Ihnen nach Abholung des Containers in Rechnung gestellt.<br>
                  Die Preise gelten vorbehaltlich Sortenänderung nach Sortenprüfung.<br>
                  Alle Preise verstehen sich inkl. gesetzlicher MwSt..<br><br>* Bitte beachten Sie das <span class="clicker"
                                                                                             @click="showMindestlastWarning = true">Mindestgewicht <i
                    class="fa-regular fa-info-circle"></i></span> von
                  <template v-if="fullDisplay && fullDisplay.waste_id && fullDisplay.waste_id.waste_amount">
                    {{ Math.round(fullDisplay.waste_id.waste_amount) + '\u00A0kg' }}
                  </template>
                  .
                </p>
                <button class="btn" @click="step='ansprech'" v-if="step!=='ansprech'">Akzeptieren und weiter</button>
              </div>
            </div>
          </div>
          <div class="columns large-6 medium-12 small-12" v-if="step ==='ansprech'">
            <div class="singleWrapper">
              <h2>Ihre Daten</h2>
              <div class="box" style="margin-bottom: 24px;" v-if="!isLoggedIn">
                <div class="row">
                  <div class="columns xlarge-12 large-12 medium-12 small-12">
                    <div class="inputGroup">
                      <input type="text" style="margin-top: 3px;margin-right: 3px;" placeholder="E-Mail-Adresse"
                             :readonly="(emailIsRegisteredComplete)" v-model="save.newAnsprech.email"
                             @keypress.enter="checkValidEmail" @blur="checkValidEmail"/>
                      <button style="width: 45px;" type="button" v-if="emailIsRegisteredComplete"
                              class="btn btn-danger btn-registered-complete"
                              @click="emailIsRegisteredComplete = false; save.newAnsprech.email = ''; emailIsRegistered=false;">
                        x
                      </button>
                      <button v-else type="button" class="btn btn-validate-email" @click="checkValidEmail">Weiter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box" v-if="!emailIsRegistered && emailIsRegisteredComplete">
                <div class="row">
                  <div class="columns large-6 medium-12 small-12">
                    <h4>Ansprechpartner</h4>
                    <input type="text" name="firstname" v-model="save.newAnsprech.firstname" placeholder="Vorname *"/>
                    <input type="text" name="lastname" v-model="save.newAnsprech.lastname" placeholder="Nachname *"/>
                    <input type="text" name="email" v-model="save.newAnsprech.email2" @blur="compareEMails" placeholder="E-Mail *"/>
                    <div v-if="save.newAnsprech.email2 && save.newAnsprech.email && (save.newAnsprech.email !== save.newAnsprech.email2)" style="font-size: 12px; color: red;margin-bottom: 12px;">Die E-Mail-Adressen stimmen nicht überein.</div>
                    <input type="text" name="phone" v-model="save.newAnsprech.phone" required placeholder="Telefon *"/>

                    <template v-if="save.needsExtraAddress">
                      <h4>Rechnungsadresse</h4>
                      <input type="text" name="company_invoice" v-model="save.newRechnung.company" placeholder="Firma"/>
                      <input type="text" name="firstname_invoice" v-model="save.newRechnung.firstname" placeholder="Vorname *"/>
                      <input type="text" name="lastname_invoice" v-model="save.newRechnung.lastname" placeholder="Nachname *"/>
                      <input type="text" name="street_invoice" v-model="save.newRechnung.street" placeholder="Straße *"/>
                      <input type="text" name="number_invoice" v-model="save.newRechnung.number" placeholder="Hausnummer *"/>
                      <input type="text" name="zip_invoice" v-model="save.newRechnung.zip" placeholder="Postleitzahl *"/>
                      <input type="text" name="city_invoice" v-model="save.newRechnung.city" placeholder="Ort *"/>
                    </template>
                  </div>
                  <div class="columns large-6 medium-12 small-12">
                    <h4>Lieferadresse</h4>
                    <input type="text" name="company_delivery" v-model="save.newLocation.company" placeholder="Firma"/>
                    <input type="text" name="street_delivery" v-model="save.newLocation.street" placeholder="Straße *"/>
                    <input type="text" name="number_delivery" v-model="save.newLocation.number" placeholder="Hausnummer *"/>
                    <input type="text" name="zip_delivery" v-model="save.newLocation.zip" placeholder="Postleitzahl" readonly/>
                    <input type="text" name="city_delivery" v-model="save.newLocation.city" readonly placeholder="Ort *"/>
                  </div>
                </div>
                <div class="row">
                  <div class="columns xlarge-6 large-12 medium-12 small-12">
                    <h4>Stellfläche</h4>
                    <div class="btn-group btn-group-50">
                      <button class="btn" @click="save.newLocation.stell = false;showInlineStell = false;"
                              :class="(save.newLocation.stell === false) ? 'active': ''">
                        <i class="fa-regular fa-home"></i> Private Fläche
                      </button>
                      <button class="btn" @click="save.newLocation.stell = true;showInlineStell = true;"
                              :class="(save.newLocation.stell === true ) ? 'active': ''">
                        <i class="fa-regular fa-road"></i> Öffentliche Fläche
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="showInlineStell">
                  <div class="columns large-12 medium-12 small-12">
                    <h5 class="modal-title">Information Stellgenehmigung</h5>
                    <div class="row" v-if="$store && $store.state && $store.state.prices">
                      <div class="columns large-12 medium-12 small-12">
                        <div class="inlineStell" v-html="$store.state.prices.stellgenehmigung"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row invoiceButtons">
                  <div class="columns large-6 medium-12 small-12">
                    <button class="btn" @click="save.needsExtraAddress=false"
                            :class="(!save.needsExtraAddress) ? 'active': ''">
                      Rechnungsadresse <br>ist Lieferadresse
                    </button>
                  </div>
                  <div class="columns large-6 medium-12 small-12">
                    <button class="btn" @click="save.needsExtraAddress=true"
                            :class="(save.needsExtraAddress) ? 'active': ''">
                      abweichende <br>Rechnungsadresse
                    </button>
                  </div>
                </div>
              </div>
              <div class="box" v-if="emailIsRegistered && emailIsRegisteredComplete">
                <p><strong>Ihre E-Mail-Adresse ist bereits registriert. Klicken Sie auf „Jetzt einloggen“. Sie werden an
                  unser Kundenportal weitergeleitet. Ihr Angebot erhalten Sie automatisch per Mail.</strong></p>
                <a class="btn btn-green" style="margin-bottom: 0;" @click="sendMailToCustomer"
                   :href="'https://kundenportal.meindl-entsorgung.de/?configurationWaste='+save.waste_id+'&configurationContainer='+save.container_id+'&email='+save.newAnsprech.email"
                   target="_blank">Jetzt
                  einloggen
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex" v-if="step === 'ansprech'">
          <div class="columns large-6 medium-12 small-12">
            <button class="btn" @click="step='location'">Schritt zurück</button>
          </div>
          <div class="columns large-6 medium-12 small-12">
            <template
              v-if="(save.newLocation.stell === false || save.newLocation.stell === true) && (!save.needsExtraAddress && save.newAnsprech.firstname && save.newAnsprech.lastname && save.newAnsprech.email && save.newAnsprech.email2 && save.newLocation.street && save.newLocation.number &&  save.newLocation.city && save.newAnsprech.phone && (save.newAnsprech.email === save.newAnsprech.email2)) || (save.needsExtraAddress && save.newRechnung.firstname  && save.newRechnung.lastname  && save.newRechnung.street && save.newRechnung.number  && save.newRechnung.zip  && save.newRechnung.city  && save.newAnsprech.firstname && save.newAnsprech.lastname && save.newAnsprech.email && save.newAnsprech.email2 && save.newLocation.street && save.newLocation.number && save.newLocation.city && save.newAnsprech.phone && (save.newAnsprech.email === save.newAnsprech.email2) && (save.newLocation.stell === false || save.newLocation.stell === true)) ">

              <button class="btn" @click="step = 'overview'">Bestätigen</button>
            </template>
            <template v-else>
              <button class="btn btn-disabled" disabled>Bestätigen</button>
            </template>
          </div>
        </div>
        <div class="row flex" v-if="step === 'overview'">
          <div class="columns large-12 medium-12 small-12">
            <div class="singleWrapper">
              <h2>Zusammenfassung</h2>
              <div class="box">
                <div class="row">
                  <div class="columns xlarge-6 large-12 medium-12 small-12">
                    <div class="submitBox" ref="boxContent">
                      <p><strong
                        v-if="fullDisplay && fullDisplay.waste_id && fullDisplay.waste_id.waste_name && fullDisplay.container_id && fullDisplay.container_id.title">Container
                        für {{ fullDisplay.waste_id.waste_name }}</strong><br>
                        in
                        <template v-if="$store && $store.state && $store.state.order && $store.state.order.zip">
                          {{ $store.state.order.zip }}
                        </template>
                        {{ this.getSelectedCityByZip }}.<br>
                        {{ fullDisplay.container_id.title }}
                      </p>
                      <table v-if="$store.state.prices">
                        <tbody>
                        <tr>
                          <td style="text-align: left;">Transportkosten (Container liefern und wieder abholen)</td>
                          <td style="text-align: right;">{{ priceEuro($store.state.prices.transport) }}</td>
                        </tr>
                        <tr>
                          <td style="text-align: left;">Miete monatlich (14-Tage Mietfrei)</td>
                          <td style="text-align: right;">{{ priceEuro($store.state.prices.rentMonthly) }}</td>
                        </tr>
                        <tr>
                          <td style="text-align: left;">Entsorgungskosten pro <template v-if="showWaste && showWaste.default_unit === 't'">Tonne</template><template v-else>{{showWaste.default_unit}}</template></td>
                          <td style="text-align: right;">{{ priceEuro($store.state.prices.pricePerTon) }}</td>
                        </tr>
                        <tr v-if="fullDisplay.waste_id.dangerwaste && $store.state.prices.dangerwaste">
                          <td style="text-align: left;">Begleitscheingebühr für Entsorgung von gefährlichen Abfällen
                          </td>
                          <td style="text-align: right;">{{ priceEuro($store.state.prices.dangerwaste) }}</td>
                        </tr>
                        <template v-for="add in fullDisplay.waste_id.additionals">
                          <tr v-if="save.additionals[add.id].amount > 0">
                            <td style="text-align: left;">{{ add.container_name }}</td>
                            <td style="text-align: right;">
                              <template
                                v-if="add.add_price && save.additionals[add.id].amount && save.additionals[add.id].amount > 0 && save.additionals[add.id].id">
                                {{ priceEuro(add.add_price) }} x {{ save.additionals[add.id].amount }} =
                                {{ priceEuro(add.add_price * save.additionals[add.id].amount) }}
                              </template>
                            </td>
                          </tr>
                        </template>
                        <tr>
                          <td>Bezahlart</td>
                          <td style="text-align: right;">Rechnung</td>
                        </tr>
                        </tbody>
                      </table>
                      <p v-if="save.newLocation.stell">Zzgl. Gebühren für die Beantragung der öffentlichen Stellfläche
                      </p>
                      <div class="row">
                        <div class="columns large-6 medium-12 small-12">
                          <h4>Lieferadresse</h4>
                          <p>
                            <template v-if="save.newLocation.company">{{ save.newLocation.company }}<br></template>
                            {{ save.newAnsprech.firstname }} {{ save.newAnsprech.lastname }}<br>
                            {{ save.newLocation.street }} {{ save.newLocation.number }}<br>
                            {{ save.newLocation.zip }} {{ save.newLocation.city }}<br><br>
                          </p>
                          <h4>Ansprechpartner</h4>
                          <p>{{ save.newAnsprech.firstname }} {{ save.newAnsprech.lastname }}<br>
                            {{ save.newAnsprech.email }}<br>
                            {{ save.newAnsprech.phone }}
                          </p>
                        </div>
                        <div class="columns large-6 medium-12 small-12">
                          <h4>Rechnungsadresse</h4>
                          <p v-if="save.needsExtraAddress">
                            <template v-if="save.newRechnung.company">{{ save.newRechnung.company }}<br></template>
                            {{ save.newRechnung.firstname }} {{ save.newRechnung.lastname }}<br>
                            {{ save.newRechnung.street }} {{ save.newRechnung.number }}<br>
                            {{ save.newRechnung.zip }} {{ save.newRechnung.city }}<br><br>
                            {{ save.newRechnung.email }}<br>
                          </p>
                          <p v-else>
                            <template v-if="save.newLocation.company">{{ save.newLocation.company }}<br></template>
                            {{ save.newAnsprech.firstname }} {{ save.newAnsprech.lastname }}<br>
                            {{ save.newLocation.street }} {{ save.newLocation.number }}<br>
                            {{ save.newLocation.zip }} {{ save.newLocation.city }}<br>
                          </p>
                        </div>
                      </div>
                      <div class="hiddenEmailValues">
                        <p v-if="save.lieferdatum || save.abholdatum">
                          <template v-if="save.lieferdatum"><strong>Voraussichtliches Lieferdatum:</strong>
                            {{ $filters.formatDate(save.lieferdatum) }}<br></template>
                          <template v-if="save.abholdatum"><strong>Voraussichtliches Abholdatum:</strong>
                            {{ $filters.formatDate(save.abholdatum) }}
                          </template>
                        </p>
                        <p v-if="save.stellplatzbeschreibung"><strong>Stellplatzbeschreibung:</strong><br>
                          {{ save.stellplatzbeschreibung }}</p>
                        <p v-if="save.kommentar"><strong>Kommentar:</strong><br> {{ save.kommentar }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="columns large-12 medium-12 small-12">
                        <!-- back button -->
                        <button class="btn" @click="step='ansprech'">Schritt zurück</button>
                      </div>
                    </div>
                  </div>
                  <div class="columns xlarge-6 large-12 medium-12 small-12">
                    <div class="finishSide">
                      <VueDatePicker
                        model-type="yyyy-MM-dd" format="dd.MM.yyyy" :auto-apply="true" :close-on-auto-apply="true"
                        locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true"
                        week-start="1" :disabled-dates="dateFilter"
                        v-model="save.lieferdatum" placeholder="gewünschtes Lieferdatum"
                      />
                      <div class="checkbox">
                        <input type="checkbox" v-model="save.abholdatum_check" id="abholdatum_check"/><label
                        for="abholdatum_check" class="abholdatum_check_label">Hier können Sie direkt die Abholung beauftragen</label>
                      </div>
                      <VueDatePicker v-if="save.abholdatum_check" :min-date="abholMinDate"
                                     model-type="yyyy-MM-dd" format="dd.MM.yyyy" :auto-apply="true"
                                     :close-on-auto-apply="true" week-start="1"
                                     locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen"
                                     :teleport="true" :disabled-dates="dateFilter"
                                     v-model="save.abholdatum" placeholder="gewünschtes Abholdatum"
                      />
                      <textarea v-model="save.stellplatzbeschreibung" placeholder="exakte Stellplatzbeschreibung"
                                cols="30" rows="3"></textarea>
                      <textarea v-model="save.kommentar" placeholder="Kommentar" cols="30" rows="3"></textarea>
                      <div class="checkbox"><input type="checkbox" v-model="save.privacy" id="privacy" required/><label
                        for="privacy">Ich habe die <a href="https://kundenportal.meindl-entsorgung.de/datenschutz"
                                                      target="_blank">Datenschutzerklärung</a> und die <a
                        href="https://kundenportal.meindl-entsorgung.de/agb" target="_blank">AGB</a> gelesen und
                        bestätige diese.</label></div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="save.confirmSorten" id="sorteninfo" required/><label
                        for="sorteninfo">Ich habe die <span @click="showCombinedDescs = true" class="stellgenehmigung">Hinweise</span>
                        gelesen.</label></div>
                      <div class="checkbox" v-if="!save.newLocation.stell">
                        <input type="checkbox" v-model="save.stellgenehmigung" id="stellgenehmigung" required/> <label
                        for="stellgenehmigung">Der Container steht nicht auf einer öffentlichen Fläche.
                        Es wird keine <span @click="showStell = true" class="stellgenehmigung">Stellgenehmigung</span>
                        für öffentliche Flächen benötigt.
                      </label></div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="save.globalAccept" id="globalAccept" required/> <label
                        for="globalAccept">Die Gesamtkosten werden Ihnen nach Abholung des Containers in Rechnung gestellt.<br>
                        Die Preise gelten vorbehaltlich Sortenänderung nach Sortenprüfung.<br>
                        Alle Preise verstehen sich inkl. gesetzlicher MwSt..<br><br>* Bitte beachten Sie das <span class="clicker"
                                                                                                   @click="showMindestlastWarning = true">Mindestgewicht <i
                          class="fa-regular fa-info-circle"></i></span> von
                        <template v-if="fullDisplay && fullDisplay.waste_id && fullDisplay.waste_id.waste_amount">
                          {{ Math.round(fullDisplay.waste_id.waste_amount) + '\u00A0kg' }}
                        </template>
                        .
                      </label>
                      </div>
                      <template
                        v-if="save.privacy && save.confirmSorten && (save.newLocation.stell || save.stellgenehmigung) && save.globalAccept">
                        <button class="btn" @click="submitAll" :disabled="isSubmitting">Bestellung abschließen</button>
                      </template>
                      <template v-else>
                        <button class="btn btn-disabled" disabled>Bestellung abschließen</button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="showWasteDesc">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" v-if="wasteDescOb.waste_name">{{ wasteDescOb.waste_name }}</h5>
              <h5 class="modal-title" v-else>{{ wasteDescOb.title }}</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showWasteDesc = false">x
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="columns large-12 medium-12 small-12">

                  <div v-html="wasteDescOb.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showCombinedDescs">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Hinweise</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showCombinedDescs = false">x
              </button>
            </div>
            <div class="modal-body" v-if="fullDisplay">

              <div class="row">
                <div class="columns large-12 medium-12 small-12"
                     v-if="fullDisplay.waste_id && fullDisplay.waste_id.description">
                  <h5 class="modal-title" style="margin-bottom: 12px;" v-if="fullDisplay.waste_id.waste_name">Abfallart: {{ fullDisplay.waste_id.waste_name }}</h5>
                  <div v-html="fullDisplay.waste_id.description"></div>
                </div>
              </div>
              <div class="row">
                <div class="columns large-12 medium-12 small-12"
                     v-if="fullDisplay.container_id && fullDisplay.container_id.description">
                  <h5 class="modal-title" style="margin-bottom: 12px;" v-if="fullDisplay.container_id.title">Container: {{ fullDisplay.container_id.title }}</h5>
                  <div v-html="fullDisplay.container_id.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showStell">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Information Stellgenehmigung</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showStell = false">x
              </button>
            </div>
            <div class="modal-body">
              <div class="row" v-if="$store && $store.state && $store.state.prices">
                <div class="columns large-12 medium-12 small-12">
                  <div v-html="$store.state.prices.stellgenehmigung"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showEMailCompareWarning">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Warnung</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showEMailCompareWarning = false">x
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="columns large-12 medium-12 small-12">
                  <p>Die eingegebenen E-Mail-Adressen stimmen nicht überein. Bitte überprüfen Sie Ihre Eingabe.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showMindestlastWarning">
      <div class="modal fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg purpleView">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Mindestlastunterschreitung</h5>
              <button type="button" class="btn-close" style="margin-left: 24px;float: right;"
                      @click="showMindestlastWarning = false">x
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="columns large-12 medium-12 small-12">
                  <p>Eine Verwendung von Messwerten unterhalb der Mindestlast ist lt. Mess- und Eichgesetz unzulässig.
                    <br>Daher wird bei Unterschreitung pauschal abgerechnet.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </main>
</template>
<script>
import axios from "axios";
import jQuery from "jquery";
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  name: "Type",
  data: function () {
    return {
      filter: {
        searchWasteType: '',
        filterActive: false,
        searchContainer: '',
        locationSearch: '',
      },
      step: 'waste',
      save: {
        nacherfassung: false,
        category_id: false,
        waste_id: false,
        container_id: false,
        genehmigung: false,
        location_id: false,
        ansprech_id: false,
        besteller_id: false,
        direktTransport: false,
        fremdkz: '',
        newLocation: {
          company: '',
          street: '',
          number: '',
          zip: this.$store.state.order.zip,
          city: '',
          stell: false
        },
        newRechnung: {
          company: '',
          firstname: '',
          lastname: '',
          street: '',
          number: '',
          zip: '',
          city: '',
        },
        newAnsprech: {
          firstname: '',
          lastname: '',
          email: '',
          email2: '',
          phone: ''
        },
        newAnsprechs: [],
        confirmSorten: false,
        lieferdatum: '',
        abholdatum_check: false,
        abholdatum: '',
        stellplatzbeschreibung: '',
        amount: '',
        kommentar: '',
        additionals: {},
        privacy: false,
        stellgenehmigung: false,
        globalAccept: false,
        htmlContentBase64: '',
        needsExtraAddress: false,
      },
      showThankYou: false,
      nacherfassung: {
        possible: false,
        done: false,
        email: false,
        phone: false,
        data: {
          id: '',
          email: '',
          phone: ''
        }
      },
      showWaste: false,
      showWasteConfirm: false,
      showWasteDesc: false,
      showModalLogin: false,
      showCombinedDescs: false,
      showStell: false,
      showInlineStell: false,
      showEMailCompareWarning: false,
      showMindestlastWarning: false,
      wasteDescOb: {},
      categories: [],
      allContainers: [],
      login: {
        username: '',
        password: '',
      },
      fullDisplay: {
        waste_id: {},
        container_id: {},
        container_counter: 'no',
        location_id: {},
        ansprech_id: {},
        zip: ''
      },
      su: true,
      isLoggedIn: false,
      emailIsRegistered: false,
      emailIsRegisteredComplete: false,
      isSubmitting: false,
      errorMessage: '',
    }
  },
  components: {
    VueDatePicker
  },
  computed: {
    abholMinDate() {
      if (this.save.lieferdatum) {
        const lieferDate = new Date(this.save.lieferdatum);
        const nextDay = new Date(lieferDate);
        nextDay.setDate(lieferDate.getDate() + 1);
        return nextDay.toISOString().split("T")[0]; // Return as 'yyyy-MM-dd'
      }
      return null; // Return null if lieferdatum is not set
    },
    getSelectedCityByZip() {
      const zip = String(this.$store.state.order.zip);
      let city = '';
      switch (zip) {
        case "93138":
          city = "Lappersdorf";
          break;
        case "93092":
          city = "Barbing";
          break;
        case "93093":
          city = "Donaustauf";
          break;
        case "93182":
          city = "Duggendorf";
          break;
        case "93183":
          city = "Kallmünz";
          break;
        case "93096":
          city = "Köfering";
          break;
        case "93073":
          city = "Neutraubling";
          break;
        case "93152":
          city = "Nittendorf";
          break;
        case "93083":
          city = "Obertraubling";
          break;
        case "93080":
          city = "Pentling";
          break;
        case "93186":
          city = "Pettendorf";
          break;
        case "93188":
          city = "Pielenhofen";
          break;
        case "93128":
          city = "Regenstauf";
          break;
        case "93161":
          city = "Sinzing";
          break;
        case "93105":
          city = "Tegernheim";
          break;
        case "93173":
          city = "Wenzenbach";
          break;
        case "93195":
          city = "Wolfsegg";
          break;
        case "93197":
          city = "Zeitlarn";
          break;
        case "93055":
        case "93051":
        case "93049":
        case "93047":
        case "93057":
        case "93053":
        case "93059":
          city = "Regensburg";
          break;
        case "93077":
          city = "Bad Abbach";
          break;
        case "93087":
          city = "Alteglofsheim";
          break;
        case "93177":
          city = "Altenthann";
          break;
        case "93089":
          city = "Aufhausen";
          break;
        case "93090":
          city = "Bach an der Donau";
          break;
        case "93176":
          city = "Beratzhausen";
          break;
        case "93170":
          city = "Bernhardswald";
          break;
        case "93179":
          city = "Brennberg";
          break;
        case "93164":
          city = "Laaber/Brunn";
          break;
        case "93180":
          city = "Deuerling";
          break;
        case "93095":
          city = "Hagelstadt";
          break;
        case "93155":
          city = "Hemau";
          break;
        case "93098":
          city = "Mintraching";
          break;
        case "93099":
          city = "Mötzing";
          break;
        case "93101":
        case "93102":
          city = "Pfatter";
          break;
        case "93104":
          city = "Sünching";
          break;
        case "84069":
          city = "Schierling";
          break;
        case "93107":
          city = "Thalmassing";
          break;
        case "93109":
          city = "Wiesent";
          break;
        case "93086":
          city = "Wörth an der Donau";
          break;
        case "93133":
          city = "Burglengenfeld";
          break;
        case "93345":
          city = "Hausen";
          break;
        case "93346":
          city = "Ihrlerstein";
          break;
        case "93309":
          city = "Kelheim";
          break;
        case "84085":
          city = "Langquaid";
          break;
        case "93142":
          city = "Maxhütte-Haidhof";
          break;
        case "93351":
          city = "Painten";
          break;
        case "93342":
          city = "Saal an der Donau";
          break;
        case "93158":
          city = "Teublitz";
          break;
        case "93356":
          city = "Teugn";
          break;
        case "93192":
          city = "Wald";
          break;
        default:
          city = "Regensburg"; // Fallback for unmatched ZIPs
      }
      this.$store.state.order.city = city;
      return city;
    }
  },
  methods: {
    checkValidEmail() {
      if (this.checkStringIsValidEmail(this.save.newAnsprech.email)) {
        axios.get(this.$store.state.apiUrl + "config?type=ansprech&email=" + this.save.newAnsprech.email,).then((r) => {
          if (r.data.result && r.data.result.userId) {
            this.emailIsRegistered = true;
            this.emailIsRegisteredComplete = true;
          } else {
            this.emailIsRegistered = false;
            this.emailIsRegisteredComplete = true;
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    },
    dateFilter(date) {
      // Today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to 00:00:00 for accurate comparison

      // Determine the weekday (0 = Sunday, 6 = Saturday)
      const weekday = today.getDay();

      // Calculate the adjusted minimum date based on the logic
      let minDate = new Date(today);
      if (weekday === 5) { // Friday
        minDate.setDate(today.getDate() + 4);
      } else if (weekday === 0) { // Sunday
        minDate.setDate(today.getDate() + 2);
      } else if (weekday === 6) { // Saturday
        minDate.setDate(today.getDate() + 3);
      } else { // Monday to Thursday
        minDate.setDate(today.getDate() + 2);
      }

      // Convert the date to "yyyy-MM-dd" in local time (Germany)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
      const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
      const dateString = `${year}-${month}-${day}`; // Format as "yyyy-MM-dd"

      // Get Feiertage from store
      const feiertage = Object.values(this.$store.state.prices.feiertage);

      // Check if the date is a holiday
      const isHoliday = feiertage.includes(dateString);

      // Check if the date is a weekday (not Saturday/Sunday)
      const dayOfWeek = date.getDay(); // 0 = Sunday, 6 = Saturday
      const isWeekday = dayOfWeek !== 0 && dayOfWeek !== 6;

      // Check if the date is at least the adjusted minimum date
      const isValidFutureDate = date >= minDate;

      // Disable the date if it's not a valid weekday or is a holiday
      return !(isWeekday && isValidFutureDate) || isHoliday;
    },
    setMinDateAbhol() {
      console.log(this.save.lieferdatum);
      if (this.save.abholdatum && this.save.lieferdatum) {
        // If abholdatum is before lieferdatum, adjust abholdatum
        if (this.save.abholdatum < this.save.lieferdatum) {
          this.save.abholdatum = this.save.lieferdatum;
        }
      }
    },
    selectItem(id, type, model) {
      if (type === 'ansprech_id') {
        if (model.AdrTelefon_AdrTelId !== undefined) {
          this.nacherfassung.data.id = model.AdrTelefon_AdrTelId;
          this.nacherfassung.name = model.AdrTelefon_Ansprech;
          var falseIt = 0;
          if (model.AdrTelefon_Telefon === undefined || model.AdrTelefon_Telefon === '' || Array.isArray(model.AdrTelefon_Telefon) || !this.checkStringHaveNumbers(model.AdrTelefon_Telefon)) {
            this.nacherfassung.possible = true;
            this.nacherfassung.phone = true;
          } else {
            falseIt++;
          }
          if (model.AdrTelefon_Email === undefined || model.AdrTelefon_Email === '' || Array.isArray(model.AdrTelefon_Email) || !this.checkStringIsValidEmail(model.AdrTelefon_Email)) {
            this.nacherfassung.possible = true;
            this.nacherfassung.email = true;
          } else {
            falseIt++;
          }
          if (falseIt === 2) {
            this.nacherfassung.possible = false;
          }
        }
      }
      if (type === 'category_id') {
        this.save.waste_id = false;
        this.save.container_id = false;
      }
      if (type === 'location_id') {
        var toChangeLocation = this.locations[id];
        var prefill = {
          conwin_name1: toChangeLocation.conwin_name1,
          conwin_name2: toChangeLocation.conwin_name2,
          conwin_name3: toChangeLocation.conwin_name3,
          conwin_bez: toChangeLocation.conwin_bez,
          conwin_strasse: toChangeLocation.conwin_strasse,
          conwin_hausnr: toChangeLocation.conwin_hausnr,
          conwin_plz: toChangeLocation.conwin_plz,
          conwin_ort: toChangeLocation.conwin_ort,
        }
        this.save.fremdkz = this.locations[id].fremdkz;
        this.locationChanges[id] = prefill;
      }
      if (this.save[type] === id) {
        this.save[type] = false;
      } else {
        this.save[type] = id;
      }
      if (model) {
        this.fullDisplay[type] = model
      }
    },
    checkStringIsValidEmail(string) {
      return /\S+@\S+\.\S+/.test(string);
    },
    sendMailToCustomer() {
      let VueInner = this;
      // download pdf from base64 from api
      const contentElement = this.$refs.pdfContent;
      const clonedElement = contentElement.cloneNode(true);
      const button = clonedElement.querySelector('button');
      if (button) {
        button.remove();
      }
      const htmlString = clonedElement.innerHTML;

      // Prepare the payload with both `this.save` and the HTML content
      const payload = {
        ...this.save, // Include the save object
        htmlContent: htmlString, // Add the HTML content
      };
      axios.post(this.$store.state.apiUrl + "configurator?type=sendOffer" , payload).then((r) => {
        console.log(r);
      }).catch((e) => {
        console.log(e);
      });
    },
    checkStringHaveNumbers(string) {
      // replace empty spaces
      string = string.replace(/\s/g, '');
      return /\d/.test(string);
    },
    getCategories() {
      axios.get(this.$store.state.apiUrl + "config?type=binvollSorts&zip=" + this.$store.state.order.zip,).then((r) => {
        this.categories = r.data.result.data;
      }).catch((e) => {
        console.log(e);
      });
    },
    priceEuro(value) {
      return Number(value).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
    },
    checkItems(category) {
      let a;
      for (a in category.items) {
        if (category.items[a].filterMatch === 1) {
          return true;
        }
      }
      return false;
    },
    setAdditionals() {
      let VueInner = this;
      this.save.additionals = {};
      let a;
      for (a in this.fullDisplay.waste_id.additionals) {
        this.save.additionals[this.fullDisplay.waste_id.additionals[a].id] = {
          id: null,
          amount: ''
        };
      }
      axios.get(this.$store.state.apiUrl + "config?type=configuratorZip&waste_id=" + this.save.waste_id + "&container_id=" + this.save.container_id + "&zip=" + this.$store.state.order.zip,).then((r) => {
        VueInner.$store.state.prices = r.data.result.data;
      }).catch((e) => {
        console.log(e);
      });
    },
    downloadOffer() {
      // download pdf from base64 from api
      const contentElement = this.$refs.pdfContent;
      const clonedElement = contentElement.cloneNode(true);
      const button = clonedElement.querySelector('button');
      if (button) {
        button.remove();
      }
      const htmlString = clonedElement.innerHTML;

      // Prepare the payload with both `this.save` and the HTML content
      const payload = {
        ...this.save, // Include the save object
        htmlContent: htmlString, // Add the HTML content
      };
      axios.post(this.$store.state.apiUrl + "configurator?type=downloadOffer" , payload).then((r) => {
        // download pdf
        const linkSource = `data:application/pdf;base64,${r.data.result.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "Angebot_Meindl.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }).catch((e) => {
        console.log(e);
      });
    },
    showSortenInfoDirect(waste) {
      if (waste) {
        this.wasteDescOb = waste;
        this.showWasteDesc = true;
      }
      // show in confirm popup
    },
    compareEMails() {
      if (this.save.newAnsprech.email !== this.save.newAnsprech.email2 && this.save.newAnsprech.email !== '' && this.save.newAnsprech.email2 !== '') {
        this.showEMailCompareWarning = true;
      }
    },
    submitAll() {
      if (this.isSubmitting) return; // Prevent multiple submissions
      this.isSubmitting = true;
      let VueInner = this;
      let boxHTML = this.$refs.boxContent ? this.$refs.boxContent.innerHTML : '';
      // Convert HTML content to Base64
      let boxHTMLBase64 = btoa(unescape(encodeURIComponent(boxHTML)));
      this.save.htmlContentBase64 = boxHTMLBase64;
      let postContent = {
        data: this.save,
      }
      axios.post(this.$store.state.apiUrl + "configurator", postContent).then((r) => {
        this.showThankYou = true;
      }).catch((e) => {
        console.log(e);
        this.errorMessage = e.response.data.todo.content;
        console.log(e);
      });
    },
  },
  // set progress to 10
  created() {
    this.getCategories();
    if (this.$store.state.order.zip === '') {
      this.$router.push('/');
    }
  },
  watch: {
    '$data.filter.searchWasteType': function () {
      let lengthFilter = this.filter.searchWasteType.length;
      if (lengthFilter > 0) {
        this.filter.filterActive = true
      } else {
        this.filter.filterActive = false;
      }
      let index;
      let key;
      let search;
      let string;
      let tagString;
      for (key in this.categories) {
        for (index in this.categories[key].items) {
          if (this.filter.searchWasteType.length <= 0) {
            this.categories[key].items[index].filterMatch = 1;
          } else {
            this.categories[key].items[index].filterMatch = 0;
            if (this.filter.searchWasteType.length > 0) {
              search = this.filter.searchWasteType.toLowerCase();
              string = this.categories[key].items[index].waste_name.toLowerCase();
              tagString = this.categories[key].items[index].tags.toLowerCase();
              if (string.indexOf(search) >= 0 || tagString.indexOf(search) >= 0) {
                this.categories[key].items[index].filterMatch = 1;
              }
            }
          }
        }
      }
      // if (this.su) {
      setTimeout(function () {
        let catAmount = jQuery(".binvollSelect.typeCategory").length;
        if (catAmount === 1) {
          if (!jQuery(".binvollSelect.typeCategory").hasClass("active")) {
            jQuery(".binvollSelect.typeCategory").trigger("click");
          }
        }
      }, 200);
      // }
    },
    '$data.filter.locationSearch': function () {
      let lengthFilter = this.filter.locationSearch.length;
      if (lengthFilter > 0) {
        this.filter.filterActive = true
      } else {
        this.filter.filterActive = false;
      }
      let index;
      let key;
      let search;
      let string;
      for (key in this.locations) {
        for (index in this.locations[key]) {
          if (this.filter.locationSearch.length <= 0) {
            this.locations[key].filterMatch = 1;
          } else {
            this.locations[key].filterMatch = 0;
            if (this.filter.locationSearch.length > 0) {
              search = this.filter.locationSearch.toLowerCase();
              string = this.locations[key].address.toLowerCase() + this.locations[key].conwin_ort.toLowerCase() + this.locations[key].conwin_bez.toLowerCase();
              if (string.indexOf(search) >= 0) {
                this.$set(this.$data.locations[key], "filterMatch", 1);
              }
            }
          }
        }
      }
    },
    'this.$store.state.order.zip': {
      handler(zip) {
        const city = this.getSelectedCityByZip;
        this.save.newLocation.city = city;
        this.$store.state.order.city = city;
      },
      immediate: true // This will trigger the watcher immediately on component mount
    },
    'save.lieferdatum': function (newDate) {
      // Update abholdatum if it's earlier than lieferdatum
      if (this.save.abholdatum && this.save.abholdatum < newDate) {
        this.save.abholdatum = newDate;
      }
    }
  }
}
</script>
<style scoped>
</style>
